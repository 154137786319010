import React from 'react'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible } from 'react-grid-system'
import { graphql } from 'gatsby'

import withContext from '../../components/withContext'
import injectContext from '../../components/injectContext'
import withIntl from '../../components/withIntl'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Space from '../../components/Space'
import Navigation from '../../scenes/Enterprise/Navigation'
import Header from '../../scenes/Header'
import EnterpriseFooter from '../../scenes/Enterprise/Footer'

import headerPicture from '../../img/artworks/artwork_features_miniblock_1.png'

const Enterprise = ({ intl: { formatMessage }, data }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'enterprise.meta.title' })}
      metaDescription={formatMessage({ id: 'enterprise.meta.description' })}
      metaOGImage={headerPicture}
    />
    <Navigation />
    <Header
      crumbs={[]}
      artworkPicture={data.headerPicture.childImageSharp.fluid}
      artworkWidth={440}
      title={formatMessage({ id: 'enterprise.header.title' })}
      description={formatMessage({ id: 'enterprise.header.description' })}
      actionText={formatMessage({ id: 'enterprise.header.action.connect' })}
      actionURL={`https://wuha.typeform.com/to/QpTXJG`}
      actionNewTab={true}
    />
    <Space type="giant" />
    <Container>
      <Row nogutter>
        <Col md={5} offset={{ md: 1 }} >
          <Visible xs sm>
            <Space type="l" />
          </Visible>
        </Col>
        <Col md={5} offset={{ md: 0.5 }} >
          <Visible xs sm>
            <Space type="l" />
          </Visible>
        </Col>
      </Row>
    </Container>
    <Space type="giant" />
    <EnterpriseFooter />
  </Layout>
)

export const pageQuery = graphql`
  query {
    headerPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_1.png" }) {
      ...fluidImage
    }
  }
`

export default withContext(injectContext(withIntl(injectIntl(Enterprise))))
